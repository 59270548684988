<template>
    <div class="row no-gutters p-0">
        <div class="col-6 col-md-auto pr-md-3 mb-2 mb-md-0">
            <h2 class="text-center font-weight-bold text-nowrap mb-0">
                <strong>{{ impressionString }}</strong><small style="font-size: 50%; ">/ 5</small>
            </h2>
            <p class="text-center text-nowrap mb-1">
                Impression✨
            </p>
        </div>
        <div class="col-6 col-md-auto pr-md-3 mb-2 mb-md-0">
            <h2 class="text-center font-weight-bold text-nowrap mb-0">
                <strong>{{ cpRatioString }}</strong><small style="font-size: 50%; ">/ 5</small>
            </h2>
            <p class="text-center text-nowrap mb-1">
                CP Ratio💲
            </p>
        </div>
        <div class="col-6 col-md-auto mb-2 mb-md-0">
            <h2 class="text-center font-weight-bold mb-0">
                <strong>{{ interestingString }}</strong><small style="font-size: 50%; ">/ 5</small>
            </h2>
            <p class="text-center text-nowrap mb-1">
                Joyfulness😀
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ReviewRatings",
    props: {
        impression: {
            type: [Number, String],
        },
        cpRatio: {
            type: [Number, String],
        },
        interesting: {
            type: [Number, String],
        },
    },
    data () {
        return {

        };
    },
    computed: {
        impressionString () {
            return this.impression ?
                parseFloat(this.impression).toFixed(2) :
                "N.A.";
        },
        cpRatioString () {
            return this.cpRatio ?
                parseFloat(this.cpRatio).toFixed(2) :
                "N.A.";
        },
        interestingString () {
            return this.interesting ?
                parseFloat(this.interesting).toFixed(2) :
                "N.A.";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
